<template>
    <o-dropdown ref="dropdown" :target-ref="virtualTarget" virtual :teleportTargetOverride="teleportTargetOverride" @beforeopen="handleBeforeOpen"
        :popperOptions="popperOptions">
        <template #dropdown="scope">
            <div :ref="scope.container" class="shadow dropdown-menu card o365-grid-context-menu"
                style="overflow-y: auto;"
                :style="{
                    'z-index': teleportTargetOverride ? 2 : undefined,
                    'max-height': menuHeight+'px'
                }">
                <slot name="top" :row="row" :column="column" :close="scope.close"></slot>
                <component v-if="column.contextmenuTopSlot" :is="column.contextmenuTopSlot" :row="row" :column="column" :close="scope.close"></component>

                <slot :row="row" :column="column" :close="scope.close">
                    <!-- <small class="position-absolute text-muted" style="right: 0.5rem; top: 0.25rem" --> 
                        <!-- :title="$t('You can get the browser context menu when right-clicking while holding ctrl key down')">ctrl +</small> -->
                    <template v-if="linkValue">
                        <button class="dropdown-item" @click="copyLink()">
                            <i class="bi bi-share"></i>
                            {{$t('Copy Link')}}
                        </button>
                        <button class="dropdown-item" @click="openLink()">
                            <i class="bi bi-share"></i>
                            {{$t('Open in new tab')}}
                        </button>
                        <div class="dropdown-divider"></div>
                    </template>
                    <button class="dropdown-item" @click="handleCopy()">
                        <i class="bi bi-files me-1"></i>
                        {{$t('Copy')}}
                        <span class="float-end">Ctrl+C</span>
                    </button>
                    <button class="dropdown-item" @click="handleCopy(true)">
                        <i class="bi bi-files me-1"></i>
                        {{$t('Copy With Headers')}}
                    </button>
                    <button :disabled="!dataGridControl.state.allowUpdate" class="dropdown-item" @click="handlePaste">
                        <i class="bi bi-clipboard2 me-1"></i>
                        {{$t('Paste')}}
                        <span class="float-end">Ctrl+V</span>
                    </button>
                    <template v-if="dataGridControl.state.allowDelete">
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item" @click="handleDelete">
                            <i class="bi bi-x-lg"></i>
                            {{$t('Delete')}}
                        </button>
                        <button v-if="dataGridControl.selectionControl.selectedRows.length > 0" class="dropdown-item" @click="e => handleDelete(e, true)">
                            <i class="bi bi-x-lg"></i>
                            {{ $t('Delete Checked Rows') }}
                        </button>
                    </template>
                    <div class="dropdown-divider"></div>
                    <button v-if="selectionHasFilter" class="dropdown-item" @click="handleRemoveFilter">
                        <i class="bi bi-funnel"></i>
                        {{$t('Remove Filter')}}
                    </button>
                    <template v-else>
                        <button class="dropdown-item" @click="() => handleFilterBy()">
                            <i class="bi bi-funnel"></i>
                            {{$t('Filter By Selection')}}
                        </button>
                        <button class="dropdown-item" @click="() => handleFilterBy(true)">
                            <i class="bi bi-funnel"></i>
                            {{$t('Filter By Excluding Selection')}}
                        </button>
                    </template>
                    <template v-if="column && row && column._group">
                        <div class="dropdown-divider"></div>
                        <o-dropdown placement="right" v-if="dataGridControl.dataObject?.grouping.groupBy">
                            <template #default="collapseScope">
                                <button class="dropdown-item"  
                                    @click="collapseScope.open" :ref="collapseScope.target">
                                    <i class="bi bi-caret-up me-1"></i>
                                    {{$t('Collapse Level')}}
                                    <i class="bi bi-caret-right-fill float-end"></i>
                                </button>
                            </template>
                            <template #dropdown="collapseScope">
                                <div :ref="collapseScope.container" class="card shadow dropdown-menu rounder-0">
                                    <button v-for="(group, groupIndex) in groupBy" :key="group" class="dropdown-item"
                                        @click="() => { column._group.collapseLevel(groupIndex); scope.close();}">
                                        {{group.column.headerName}}
                                    </button>
                                </div>
                            </template>
                        </o-dropdown>
                        <template v-if="row.o_groupHasDetails">
                            <button v-if="row.o_groupCollapsed" class="dropdown-item" @click="() => { column._group.expand(row, rowIndex); scope.close();}">
                                <i class="bi bi-caret-down me-1"></i>
                                {{$t('Expand Row')}}
                            </button>
                            <button v-else class="dropdown-item" @click="() => { column._group.collapse(row, rowIndex); scope.close();}">
                                <i class="bi bi-caret-up me-1"></i>
                                {{$t('Collapse Row')}}
                            </button>
                        </template>
                    </template>

                    <template v-if="row.isNode && row.canCreateNodes">
                        <div class="dropdown-divider"></div>
                        <!--
                        <o-dropdown placement="right">
                            <template #default="collapseScope">
                                <button class="dropdown-item"  
                                    @click="collapseScope.open" :ref="collapseScope.target">
                                    <i class="bi bi-plus-lg"></i>
                                    {{$t(`Add Row`)}}
                                    <i class="bi bi-caret-right float-end"></i>
                                </button>
                            </template>
                            <template #dropdown="collapseScope">
                                <div :ref="collapseScope.container" class="card shadow dropdown-menu rounder-0">
                                -->
                                    <button class="dropdown-item" @click="() => {row.addSibling(undefined, { above: true }); scope.close();}">
                                        <i class="bi bi-arrow-up"></i>
                                        {{$t('Add Row Above')}}
                                    </button>
                                    <button class="dropdown-item" @click="() => {row.addSibling(); scope.close();}">
                                        <i class="bi bi-arrow-down"></i>
                                        {{$t('Add Row Below')}}
                                    </button>
                                    <button class="dropdown-item" @click="() => {row.addDetail(); scope.close();}">
                                        <i class="bi bi-node-plus"></i>
                                        {{$t('Add Sub Row')}}
                                    </button>
                                    <!--
                                </div>
                            </template>
                        </o-dropdown>
                        -->
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item" @click="() => {row.indent(); scope.close();}" :disabled="!row.hasSibling(true)">
                            <i class="bi bi-text-indent-left"></i>
                            {{$t('Indent')}}
                        </button>
                        <button class="dropdown-item" @click="() => {row.outdent(); scope.close();}" :disabled="!row.getParent()">
                            <i class="bi bi-text-indent-right"></i>
                            {{$t('Outdent')}}
                        </button>
                    </template>
                    
                    <template v-if="column && row && row.isNode && column.colId === 'AutoTreeGroup'">
                        <template v-if="row.hasNodes">
                            <div class="dropdown-divider"></div>

                            <button v-if="row.expanded" class="dropdown-item"
                                @click="() => { row.collapse(); scope.close(); }">
                                <i class="bi bi-caret-up me-1"></i>
                                {{$t('Collapse')}}
                            </button>
                            <button v-else class="dropdown-item"
                                @click="() => { row.expand(); scope.close(); }">
                                <i class="bi bi-caret-down me-1"></i>
                                {{$t('Expand')}}
                            </button>

                            <template v-if="row.expanded">
                                <button class="dropdown-item"
                                    @click="() => { row.details.forEach(detail => detail.expand()); scope.close(); }"
                                    :title="$t('Expand all direct children rows')">
                                    <i class="bi bi-caret-down-fill me-1"></i>
                                    {{$t('Expand All')}}
                                </button>
                                <button class="dropdown-item"
                                    @click="() => { row.details.forEach(detail => detail.collapse()); scope.close(); }"
                                    :title="$t('Collapse all direct children rows')">
                                    <i class="bi bi-caret-up-fill me-1"></i>
                                    {{$t('Collapse All')}}
                                </button>
                            </template>
                        </template>
                    </template>

                    <template v-else-if="column && row && !row.isNode && row.o_hasDetails && column.colId === 'AutoTreeGroup'">
                        <div class="dropdown-divider"></div>

                        <button v-if="row.o_expanded" class="dropdown-item"
                            @click="handleTreeify('collapse')">
                            <i class="bi bi-caret-up me-1"></i>
                            {{$t('Collapse')}}
                        </button>
                        <button v-else class="dropdown-item"
                            @click="handleTreeify('expand')">
                            <i class="bi bi-caret-down me-1"></i>
                            {{$t('Expand')}}
                        </button>

                        <template v-if="row.o_expanded">
                            <button class="dropdown-item"
                                @click="handleTreeify('expandChildren')"
                                :title="$t('Expand all direct children rows')">
                                <i class="bi bi-caret-down-fill me-1"></i>
                                {{$t('Expand All')}}
                            </button>
                            <button class="dropdown-item"
                                @click="handleTreeify('collapseChildren')"
                                :title="$t('Collapse all direct children rows')">
                                <i class="bi bi-caret-up-fill me-1"></i>
                                {{$t('Collapse All')}}
                            </button>
                        </template>
                    </template>

                </slot>
                <component v-if="column.contextmenuBottomSlot" :is="column.contextmenuBottomSlot" :row="row" :column="column" :close="scope.close"></component>
                <slot name="bottom" :row="row" :column="column" :close="scope.close"></slot>

            </div>
        </template>
    </o-dropdown>
</template>

<script setup lang="ts">
import type DataGridControl from 'o365.controls.DataGrid.ts';
import type { Ref } from 'vue';

import ODropdown from 'o365.vue.components.DropDown.vue';
import { dataGridControlKey } from 'o365.modules.vue.injectionKeys.js';
import o365_confirm from 'o365.controls.confirm.ts';
import translate from 'o365.modules.translate.ts';
import { ref, computed, inject, onMounted } from 'vue';
import { detectOverflow } from 'popper';
import logger from 'o365.modules.Logger.ts';

const props = defineProps<{
    coorX?: number,
    coorY?: number,
    confirmOptions?: object
}>();

const cX = ref(props.coorX);
const cY = ref(props.coorY);
const row = ref(null);
const rowIndex = ref(null);
const column = ref(null);
const linkValue = ref(null);
const dropdown = ref<null|any>(null);
const dataGridControl = inject<Ref<DataGridControl>|null>(dataGridControlKey, null);
const menuHeight = ref<number>();
const popperOptions = [
    {
        name: 'sizeMenuWithOverflow',
        enabled: true,
        phase: 'main',
        requiresIfExists: ['offset'],
        fn(ctx) {
            const overflow = detectOverflow(ctx.state, {});
            const height = ctx.state.elements.popper.clientHeight;
            if (overflow.bottom > 0) {
                const newHeight = height - overflow.bottom - 10;
                menuHeight.value = newHeight > 200 ? newHeight : 200;
            } else if (overflow.top > 0) {
                const newHeight = height - overflow.top - 50;
                menuHeight.value = newHeight > 200 ? newHeight : 200;
            } else {
                menuHeight.value = undefined;
            }
        }
    }
];

function setLocation(x: number, y: number) {
    cX.value = x;
    cY.value = y;
}

function initItemValues(options: {
    event?: MouseEvent,
    row: any,
    column: any
    rowIndex?: number
}) {
    row.value = options.row;
    column.value = options.column;
    linkValue.value = null;
    if (options.event) {
        const closest = options.event?.target?.closest('a');
        if (closest) {
            linkValue.value = closest.href;
        }
    }
    if (options.hasOwnProperty('rowIndex')) {
        rowIndex.value = parseInt(options.rowIndex);
    }
}

const virtualTarget = computed(() => {
    return {
        getBoundingClientRect: () => ({
            width: 0,
            height: 0,
            top: cY.value,
            right: cX.value,
            bottom: cY.value,
            left: cX.value,
        })
    };
});

const selectionHasFilter = computed(() => {
    //const selectedCols = dataGridControl.value?.cellSelectionControl?.getColumns() ?? [];
    const selectedCols = dataGridControl.value.selectionControl.getColumns(dataGridControl.value.dataColumns.columns);
    if (!selectedCols) { return false; }
    return !selectedCols.every(col => {
        const filterField = col.filterField ?? col.field;
        return !dataGridControl.value.filterObject.filterItems[filterField]?.applied;
    });
});

const groupBy = computed(() => {
    return dataGridControl.value.dataObject?.grouping.groupBy.map(group => ({
        field: group,
        column: dataGridControl.value.dataColumns.getColumn(group)
    })) ?? [];
});

function handleCopy(withHeaders) {
    /*
    if (dataGridControl.value.cellSelectionControl.selection) {
        dataGridControl.value.cellSelectionControl.copySelection(withHeaders);
    }
    */
    if (dataGridControl.value.selectionControl.selection) {
        const x = dataGridControl.value.selectionControl.selection.start.x;
        const y = dataGridControl.value.selectionControl.selection.start.y;
        const target = dataGridControl.value.container.querySelector(`.o365-body-row[data-o365-rowindex="${y}"] .o365-body-cell[data-o365-colindex="${x}"]`);
        if (target && !withHeaders) {
            const selection = document.getSelection();
            if (selection && selection.focusNode && target.contains != null) {
                if (target.contains(selection.focusNode) && selection.toString()) {
                    const result = selection.toString();
                    navigator.clipboard.writeText(result)
                        .then(() => console.log("Success copy", result))
                        .catch((error) => logger.error("error", error));
                    return
                }
            }
        }
        dataGridControl.value.selectionControl.copySelection(withHeaders, dataGridControl.value.dataColumns.columns);

        dataGridControl.value.container.querySelectorAll('.o365-cell-range-selected, .o365-cell-range-single-cell').forEach(cell => cell.classList.add('o365-cell-copy-highlight-animation'))
        setTimeout(() => {
            dataGridControl.value.container.querySelectorAll('.o365-cell-copy-highlight-animation').forEach(cell => cell.classList.remove('o365-cell-copy-highlight-animation'));
        }, 1001);
    }
    dropdown.value.close();
}

function copyLink() {
    navigator.clipboard.writeText(linkValue.value);
    dropdown.value.close();
}

function openLink() {
    window.open(linkValue.value, '_blank', 'noreferrer');
}

function handlePaste() {
    if (dataGridControl?.value == null) { return; }
    if (dataGridControl.value.selectionControl.selection) {
        const pasteEvent = new KeyboardEvent('keydown', {
            bubbles: true,
            cancelable: true,
            key: 'v',
            code: 'KeyV',
            ctrlKey: true
        });
        const x = dataGridControl.value.selectionControl.selection.start.x;
        const y = dataGridControl.value.selectionControl.selection.start.y;
        const target = dataGridControl.value.container.querySelector(`.o365-body-row[data-o365-rowindex="${y}"] .o365-body-cell[data-o365-colindex="${x}"]`);
        if (target) { target.dispatchEvent(pasteEvent); }
    }
    dropdown.value.close();
}

async function handleDelete(_pEvent, pSelectedRows = false) {
    if (dataGridControl?.value == null || dataGridControl.value.dataObject == null) { return; }
    const hasDeleteConfirm = dataGridControl.value.dataObject.deleteConfirm;
    try {
        let selectedRows: any = undefined;
        if (pSelectedRows) {
            selectedRows = dataGridControl.value.selectionControl.selectedRows;
        } else {
            if (dataGridControl.value.selectionControl.selection?.start?.container == 'N' && dataGridControl.value.dataObject.batchDataEnabled) {
                selectedRows = dataGridControl.value.selectionControl.getRows(dataGridControl.value.dataObject.batchData.data, 'N');
            } else {
                selectedRows = dataGridControl.value.selectionControl.getRows(dataGridControl.value.dataObject.data);
            }
        }
        if (selectedRows.length == 0) { return; }
        if (!hasDeleteConfirm) {
            const message = selectedRows.length == 1
                ? translate('Are you sure you want to delete the selected row?')
                : translate("Are you sure you want to delete {rows} selected rows?", { rows: selectedRows.length });
            const options = {
                message: message,
                title: props.confirmOptions?.title ?? translate('Delete confirm'),
                btnTextOk: props.confirmOptions?.btnTextOk ?? translate('Delete'),
                btnClassOk: props.confirmOptions?.btnClassOk ?? 'btn-primary',
                zIndex: 1060
            };
            await o365_confirm(options)
        }
        if (selectedRows.length == 1) {
            dataGridControl.value.dataObject.deleteItem(selectedRows[0]);
        } else if (selectedRows.length > 1) {
            dataGridControl.value.dataObject.recordSource.bulkDelete(selectedRows);
        }
        //    _dataObject.value.deleteItem(_row.value);
    } catch (ex) {
        // consent to delete not given
    } finally {
        dropdown.value.close();
    }
}

function handleFilterBy(exclude = false) {
    if (dataGridControl?.value == null) { return; }
    const selectedRows = dataGridControl.value.selectionControl.getSelection(dataGridControl.value.dataColumns.columns, undefined, {
        valueResolve: (pCol: any, pRow: any) => {
            const field = pCol.filterField ?? pCol.field ?? pCol.name;
            const filterItem = dataGridControl.value.filterObject.filterItems[field];
            const targetColumn = filterItem?.distinctOptions?.targetColumn ?? field;
            const displayColumn = filterItem?.distinctOptions?.column ?? pRow[pCol.name];
            return {
                $filter: {
                    [targetColumn]: pRow[targetColumn],
                    [displayColumn]: pRow[displayColumn] ?? pRow[pCol.name]
                },
                [field]: pRow[pCol.name]
            };
        },
        includeRef: true
    });

    if (selectedRows.length) {
        let resolvedRows = selectedRows;
        if (dataGridControl.value.props.contextMenu?.resolveFilterValues) {
            resolvedRows = selectedRows.map(row => ({ ...row, ...dataGridControl.value.props.contextMenu.resolveFilterValues(row) }));
        }
        Object.keys(dataGridControl.value.filterObject.filterItems).forEach(key => {
            const filterItem = dataGridControl.value.filterObject.filterItems[key];
            const targetColumn = filterItem.distinctOptions?.targetColumn ?? key;
            const displayColumn = filterItem.distinctOptions?.column ?? key;
            const filterTargetSet = new Set();
            const filterDisplaySet = new Set();
            let targetValueMissing = false;
            resolvedRows.forEach(row => {
                if (row.hasOwnProperty(key)) {
                    const targetValue = row.$filter[targetColumn];
                    const displayValue = row.$filter[displayColumn]; 
                    filterTargetSet.add(targetValue);
                    filterDisplaySet.add(displayValue);
                    if (targetValue == null) {
                        targetValueMissing = true;
                    }
                }
            });
            if (!filterTargetSet.size && !filterDisplaySet.size) { return; }

            if (!['string', 'number'].includes(filterItem.valueType)) {
                const [targetValue] = filterTargetSet;
                const [displayValue] = filterTargetSet;
                let value = targetValue ?? displayValue;
                if (value == null) { return; }

                dataGridControl.value.filterObject.filterItems[key].selectedValue = value;
                if (exclude) {
                    let operator = 'notequals';
                    if (['date', 'datetime', 'datetime2'].includes(filterItem.valueType)) {
                        operator = 'datenotequals';
                    }
                    dataGridControl.value.filterObject.filterItems[key].operator = operator;
                } else {
                    let operator = 'equals';
                    if (['date', 'datetime', 'datetime2'].includes(filterItem.valueType)) {
                        operator = 'dateequals';
                    }
                    dataGridControl.value.filterObject.filterItems[key].operator = operator;
                }
                return;
            }

            if (targetValueMissing) {
                const values = Array.from(filterDisplaySet).filter(x => x!=null);
                if (values.length == 0) { return; }
                dataGridControl.value.filterObject.filterItems[key].selectedValue = values; 
            } else {
                const targetValues = Array.from(filterDisplaySet).filter(x => x!=null);
                if (targetValues.length == 0) { return; }
                const displayValues = Array.from(filterDisplaySet).filter(x => x!=null);
                dataGridControl.value.filterObject.filterItems[key].selectedValue = targetValues;
                dataGridControl.value.filterObject.filterItems[key].expressionValue = displayValues;
            }
            if (exclude) {
                dataGridControl.value.filterObject.filterItems[key].operator = 'notinlist';
            } else {
                dataGridControl.value.filterObject.filterItems[key].operator = 'inlist';
            }
        });
    }


    window.requestAnimationFrame(() => {
        dataGridControl.value.filterObject.apply();
    });
    clearSelections();
    dropdown.value?.close();
}


function handleRemoveFilter() {
    if (dataGridControl?.value == null) { return; }
    //const selectedRow = dataGridControl.value.cellSelectionControl.getSelected()?.[0];
    let selectedRow = dataGridControl.value.selectionControl.getSelection(dataGridControl.value.dataColumns.columns, undefined, {
        valueResolve: (pCol: any, pRow: any) => {
            const field = pCol.filterField ?? pCol.field ?? pCol.name;
            const value = pRow[pCol.name];
            return [field, value]
        }
    })?.[0];
    if (selectedRow) {
        delete selectedRow.index;
        Object.keys(selectedRow).forEach(key => {
            dataGridControl.value.filterObject.filterItems[key].resetItem();
        });
    }
    window.requestAnimationFrame(() => {
        dataGridControl.value.filterObject.apply();
    });
    clearSelections();
    dropdown.value.close();
}

function clearSelections() {
    if (dataGridControl?.value && dataGridControl.value?.gridFocusControl) {
        window.requestAnimationFrame(() => {
            dataGridControl.value.gridFocusControl.clearFocus();
            dataGridControl.value.selectionControl.selection = null;
        });
    }
}

//--- TREEIFY ---
function handleTreeify(action) {
    if (!dataGridControl.value.dataObject) { return; }
    switch (action) {
        case 'collapse':
            dataGridControl.value.dataObject.treeify.collapse(row.value)
            break;
        case 'expand':
            dataGridControl.value.dataObject.treeify.expand(row.value)
            break;
        case 'collapseChildren':
            dataGridControl.value.dataObject.treeify.collapseChildren(row.value)
            break;
        case 'expandChildren':
            dataGridControl.value.dataObject.treeify.expandChildren(row.value)
            break;
    }
    dropdown.value.close();
}

const teleportTargetOverride = ref(null);
onMounted(() => {
    const modal = dropdown.value?.$el?.parentElement?.closest('.modal-content') ?? dropdown.value?.$el?.parentElement?.closest('.modal');
    if (modal) {
        teleportTargetOverride.value = modal;
    }
});

function handleBeforeOpen() {
    if (dataGridControl?.value.props?.beforeContextMenuOpen) {
        dataGridControl?.value.props?.beforeContextMenuOpen(row.value, column.value);
    }
}


defineExpose({ dropdown, setLocation, initItemValues });
</script>
